  
.swiper {
    width: 100%;
    height: 100%;
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60% !important;

  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .mySwiper .swiper-slide{
    opacity: 1 !important;
  }
  .swiper-wrapper{
    margin-bottom: 10px;
  }