.section3_p {
  color: #12213194;
  /* font-size: 14px; */
  line-height: 20px;
  font-weight: 400;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

body {
  background: #000;
  color: #000;
}

.swiper {
  width: 100%;
  height: 300px;
  margin-left: auto;
  margin-right: auto;
}

.swiper-slide {
  background-size: cover;
  background-position: center;
}

.mySwiper2 {
  height: 80%;
  width: 100%;
}

.mySwiper {
  height: 20%;
  box-sizing: border-box;
  padding: 10px 0;
}

.mySwiper .swiper-slide {
  width: 25%;
  height: 100%;
  opacity: 0.4;
}

.mySwiper .swiper-slide-thumb-active {
  opacity: 1;
}

.mySwiperrr {
  width: 100%;
  height: auto;
}

.swiper-image {
  width: 100%;
  height: 100px; /* Adjust this value as needed */
  object-fit: cover;
  object-position: center;
}

.swiper-slide img {
  width: 100%;
  height: auto;
  border-radius: 25px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.width_45 {
  width: 45% !important;
  border-radius: 5px;
  border: 1px solid rgba(128, 128, 128, 0.493);
}

.client_say_text h5 {
  line-height: normal;
  font-size: 14px;
  font-weight: 600;
  margin: 0;
  padding: 0;
}

.client_say_text p {
  margin: 0;
  padding: 0;
  line-height: normal;
  font-size: 12px;
  font-weight: 600;
}

.section_our_mission {
  font-size: 20px;
  line-height: normal;
  text-align: start;
}
