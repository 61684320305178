@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
  background-color: #ffffff;
}

.navbar_section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.navbar_section button {
  height: fit-content;
}

.navbar_section h4 {
  /* width: 80%; */
  color: #122131;
  font-weight: 500;
}

.hero_section_h4 {
  font-weight: 400;
  color: #122131CC;
}

.hero_section_button {
  color: white;
  background-color: #6CA6D1;
  border: none;
  border-radius: 20px;
  font-size: 18px;
}

.section_2_our_client_bar {
  margin-left: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.section_2_our_client_bar h5 {
  color: #6CA6D1;
}

.section_2_our_client_bar h4:first-child {
  color: #122131;
  /* font-size: 18px; */
  font-weight: 700;
}

.card_flex_area h4 {
  font-size: 18px;
}

.card_flex_area h5 {
  font-size: 18px;
}

.card_flex_area p {
  font-size: 15px;
}

.section2_swiper {
  font-size: 19px;
  color: #122131B2;
}

.swiper_section2_img {
  width: 80px !important;
  max-width: 80px !important;
  /* height: 32px !important; */
}

.card_flex_area {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.mySwiper_four {
  height: auto !important; /* Ensure Swiper adjusts dynamically */
  overflow: visible; /* Prevent content from being clipped */
}
.mySwiper .swiper-slide {
  width: 25%;
  height: 100%;
  opacity: 0.4;
}

.mySwiper .swiper-slide-thumb-active {
  opacity: 1;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.amount{
  color:#6CA6D1 ;
  font-weight: 400;
  font-size: 16px;
}
.request_btn_swiper_4{
  color: #6CA6D1;
  border: 1.5px solid #6CA6D1;
  border-radius: 20px;
  background-color: transparent;
  width: 100%;
  font-size: 16px;
}

.main_text_card4{
  font-size: 23px;
}

.swiper_section2_cards {
  width: 100%;
  border: 1px solid rgba(59, 56, 56, 0.055);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.mySwiper_two {
  height: auto !important;
}

.prev,
.next {
  align-content: center;
}

.next_btn {
  width: 32px !important;
  height: 32px !important;
  border: 1px solid rgba(0, 0, 0, 0.089);
  border-radius: 50%;
}

.pre_btn {
  width: 32px !important;
  height: 32px !important;
  border: 1px solid rgba(0, 0, 0, 0.089);
  border-radius: 50%;
  rotate: 180deg;
}

.mySwiper_three {
  height: 100% !important;
}

.section3_swiper {
  font-size: 20px;
  line-height: 18px;
  color: #122131CC;
}

/* Updated product_card styles */
.product_card {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.123);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
}

.product_card img {
  width: 100%;
  height: 200px; /* Adjust this value as needed */
  object-fit: cover;
  object-position: center;
}

.product_card h4,
.product_card h5,
.product_card p {
  flex-shrink: 0;
}

.product_card .d-flex {
  margin-top: auto; /* Pushes price to the bottom */
}

.product_card h6 {
  font-size: 13px;
}

.product_card p {
  font-size: 12px;
}

.product_card h5 {
  font-size: 15px;
}

.main_text_card4 {
  font-size: 18px;
}

.companies {
  font-weight: 600;
  font-size: 20px;
}

.about_h4 {
  font-weight: 700;
  font-size: 35px;
}

.mission {
  font-weight: 600;
  font-size: 20px;
}

.transforming {
  font-size: 14px;
  color: #122131;
  font-weight: 600;
}

.mision_border_main {
  border: 1px solid rgba(128, 128, 128, 0.37);
  border-radius: 5px;
}

.what_client_say {
  font-weight: 700;
  font-size: 20px;
}

.business {
  width: 100%;
  height: 1.5px;
  background-color: lightgray;
}

.PhoneInput {
  border: 1px solid lightgray;
  border-radius: 5px;
}

.PhoneInput input {
  border: none;
}

.contact_us_form {
  border: 1.543px solid lightgray;
  border-radius: 5px;
}

.contact_us_form label {
  color: #122131;
  font-size: 16px;
}

.accordion-item {
  border: none;
  border-bottom: 1px solid gray;
}

.FAQS {
  color: #122131;
  font-weight: 700;
  font-size: 20px;
}

.list_item {
  width: 30%;
}

.section3_p {
  color: #12213194;
  font-size: 24px;
  line-height: 31px !important;
  font-weight: 400;

}
.adjust-margin{
  margin-left: 10px;
}

.swiper_max_img {
  height: 465px !important;
  object-fit: cover;
}

.days {
  font-size: 24px;
}

.days-wed {
  font-size: 24px;
}

.time {
  font-size: 20px;
}

@media (max-width: 767px) {
  .swiper_max_img {
    height: 250px !important;
  }
  .days {
    font-size: 18px;
  }
  .days-wed {
    font-size: 16px;
  }
  .time {
    font-size: 15px;
  }
}

@media (max-width: 540px) {
  .days {
    font-size: 12px;
  }
  .days-wed {
    font-size: 12px;
  }
  .section2_swiper {
    font-size: 12px;
    color: #122131B2;
  }
  .card_flex_area h5 {
    font-size: 12px;
  }
  .card_flex_area p {
    font-size: 10px;
  }
  .swiper_section2_img {
    width: 100% !important;
    max-width: 50px !important;
  }
  .section3_swiper {
    font-size: 16px;
    line-height: 18px;
  }
  .about_h4 {
    font-size: 20px;
  }

  .main_text_card4 {
    font-size: 15px !important;
  }
  .section_our_mission {
    font-size: 12px;
  }
  .time {
    font-size: 10px;
  }
}
